<template>
  <section>
    <section class="GeneralPage">
      <div
        v-if="loadingWizard"
        class="loader"/>
      <div class="DetailsPage__Content">

        <div
          class="ContactVdBlock"
          style="max-width: 800px; margin-left: auto;margin-right: auto;">
          <div class="col-md-12">
            <h1 class="title">Contact Us</h1>


            <h3 style="color:#0232A1; font-weight: bold;font-size: 24px">Support Query Type</h3>
            <!-- end row -->

            <div class="row">
              <div class="col-sm-12 gForm">
                <div
                  :class="errors.has('contact_option')?'has-error':''"
                  class="form-group">
                  <select
                    v-validate="'required'"
                    v-model="contactOption"
                    data-vv-as="Contact Option"
                    name="contact_option"
                    class="form-control custom-select searchbox">
                    <option
                      v-for="(option,index) in contactOptions"
                      :key="index"
                      :value="index">{{ option }}</option>
                  </select>
                  <span
                    v-show="errors.has('contact_option')"
                    class="help-block">{{ errors.first('contact_option') }}</span>
                </div>

                <div
                  :class="errors.has('name')?'has-error':''"
                  class="form-group">
                  <input
                    v-validate="'required'"
                    v-model="name"
                    data-vv-as="Name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    class="form-control input-lg">
                  <span
                    v-show="errors.has('name')"
                    class="help-block">{{ errors.first('name') }}</span>
                </div>
                <div
                  :class="errors.has('email')?'has-error':''"
                  class="form-group">
                  <input
                    v-validate="'required|email'"
                    v-model="email"
                    data-vv-as="E-Mail"
                    name="email"
                    type="text"
                    placeholder="E-Mail"
                    class="form-control input-lg">
                  <span
                    v-show="errors.has('email')"
                    class="help-block">{{ errors.first('email') }}</span>
                </div>
                <div
                  :class="errors.has('message')?'has-error':''"
                  class="form-group">
                  <textarea
                    v-validate="'required'"
                    v-model="message"
                    data-vv-as="Message"
                    rows="6"
                    name="message"
                    placeholder="Your Message"
                    class="form-control input-lg"/>
                  <span
                    v-show="errors.has('message')"
                    class="help-block">{{ errors.first('message') }}</span>
                </div>

                <div class="form-group">
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="sitekey"
                    @verify="onVerify"
                    @expired="onExpired">
                    <button
                      :disabled="status==='submitting'"
                      class="btn btn-primary"
                      @click="send">Send</button>
                  </vue-recaptcha>
                </div>
              </div>
              <!-- end col -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <get-started/>
  </section>
</template>

<script>
import _ from 'lodash';
import swal from "sweetalert2";
import VueRecaptcha from "vue-recaptcha";

import GetStarted from "../elements/GetStarted"

export default {
  name: "ContactUs",
  components: {
    VueRecaptcha,
    GetStarted
  },
  data() {
    return {
      loadingWizard: false,
      contactOptions:[],
      contactOption: null,
      name: "",
      email: "",
      message: "",
      sitekey: "6LcC82gUAAAAAD8dTfzPZm4_YEr0RFZbsw8yMyFb",
      verifiedCode: "",
      status: ""
    };
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    recaptchaScript.async = true;
    document.head.appendChild(recaptchaScript);
  },
  created() {
    this.getContactOptions();
  },
  methods: {
    setLoading: function(value) {
      this.loadingWizard = value;
    },
    getContactOptions() {
      this.$http
        .get('bankds/staff/site/contactOptions').then((response)=> {
          this.contactOptions = response.data;
          this.contactOption = _.keys(response.data)[0];
      });
    },
    onVerify: function(response) {
      const self = this;
      this.setLoading(true);
      self.status = "submitting";
      self.$refs.recaptcha.reset();
      self.status = "";
      this.setLoading(false);
      this.verifiedCode = response;
      this.submitForm();
    },
    onExpired: function() {
      swal("Expired", "The recapcha has expired.", "error");
    },
    send() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$refs.recaptcha.execute();
        }
      });
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var data = {
            contactOption: this.contactOption,
            name: this.name,
            emailaddress: this.email,
            message: this.message,
            verified_captcha_code: this.verifiedCode
          };
          this.setLoading(true);
          this.$http
            .post("bankds/staff/contact", data)
            .then(() => {
              this.name = "";
              this.email = "";
              this.message = "";
              swal("Success", "Message Sent!", "success");
              this.setLoading(false);
            })
            .catch(error => {
              if (error.response)
                swal("Error", error.response.data.message, "error");

              this.setLoading(false);
          });
        }
      });
    }
  }
};
</script>

<style>
</style>
